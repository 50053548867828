import { useIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import EnvelopeSuccess from '../../assets/images/envelope-success.svg'
import { Layout } from '../../components/auth/Layout'
import { SimpleContent } from '../../components/auth/SimpleContent'
import { DatacMessage } from '../../components/common/DatacMessage'
import { AccountType, requestPasswordChange } from '../../requests'

interface Props {
  location: {
    state?: {
      email?: string
    }
  }
}

const RecoverPasswordSuccessPage: React.FC<Props> = ({ location }) => {
  const intl = useIntl()
  const intlRecoverPasswordSuccess = (scopedId: string) =>
    intl.formatMessage({ id: `auth.recover_password_success.${scopedId}` }, { email: location.state?.email || '' })

  const resendPasswordChangeEmail = () =>
    new Promise<void>(resolve =>
      requestPasswordChange(
        { email: location.state?.email, accountType: AccountType.User },
        {
          onSuccess: () => resolve(),
          onRequestError: () => {
            DatacMessage.error(
              intlRecoverPasswordSuccess('request_error.title'),
              intlRecoverPasswordSuccess('request_error.subtitle')
            )
            resolve()
          }
        }
      )
    )

  return (
    <Layout accountType={AccountType.User}>
      <SimpleContent
        title={intlRecoverPasswordSuccess('title')}
        subtitle={intlRecoverPasswordSuccess('subtitle')}
        message={intlRecoverPasswordSuccess('no_email')}
        submit={intlRecoverPasswordSuccess('submit')}
        Image={EnvelopeSuccess}
        onSubmit={resendPasswordChangeEmail}
      />
    </Layout>
  )
}

export default RecoverPasswordSuccessPage
export const Head = () => <title>Datacapt - Recover Password</title>
